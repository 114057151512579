@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --primary: 4, 89%, 44%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;

    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;

    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;

    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;

    --border: 216 34% 17%;
    --input: 216 34% 17%;

    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;

    --primary: 4, 89%, 44%;
    --primary-foreground: 4, 89%, 44%;

    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;

    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;

    --ring: 216 34% 17%;

    --radius: 0.5rem;
  }
} 

 @layer base {
  * {
    @apply border-border;
    box-sizing: border-box;
    margin: 0%;
    padding: 0%
    
  }
  body {
    @apply bg-background text-foreground;
    font-family:'Quicksand';
    background-color: #f8f8f8;
    overflow: hidden;
    /* font-feature-settings: "rlig" 1, "calt" 1; */
    /* width: 100%;
    height: 100%; */
    
  }
  /* Pagination container */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding: 0;
}

/* Pagination item */
.page-item {
  margin: 0 4px;
  border-radius: 8px;
  /* Adjust the spacing between items */
}

/* Pagination links */
.page-link {
  text-decoration: none;
  padding: 6px 12px;
  background-color: #fff;
  color: #000000;
  border: 1px solid #ccc;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.2s, color 0.2s;
}

.page-link:hover {
  background-color: #D51B0C;
  color: #fff;
}

/* Active page */
.page-item.active .page-link {
  background-color: #D51B0C;
  color: #fff;
  cursor: default;
  border-radius: 8px;
}

/* Disabled items */
.page-item.disabled .page-link {
  pointer-events: none;
  background-color: #dcd6d6;
  color: #000000;
  cursor: not-allowed;
  border-radius: 8px;
}

/* Ellipsis */
.page-item.disabled span.page-link {
  background-color: transparent;
  border: none;
  cursor: default;
  border-radius: 8px;
}

/* First and Last buttons */
.page-item:first-child .page-link,
.page-item:last-child .page-link {
  border-radius: 8px;
}

/* Previous and Next buttons */
.page-item:first-child .page-link::before,
.page-item:last-child .page-link::before {

  margin-right: 4px;
}

.page-item:last-child .page-link::before {}

/* Remove default list styles */
.pagination ul {
  list-style: none;
  padding: 0;
 
}

/* Center align text in pagination links */
.page-link {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}

  
} 
@keyframes tailwind-right-to-left {
  0% {
      width: 0%;
  }
  100% {
      width: 100%;
  }
}

.hovered-icon:hover + .text-red-600 {
  display: inline-block;
}

.progress {
  animation: progress 1s infinite linear;
}

.left-right {
    transform-origin: 0% 50%;
}
    @keyframes progress {
    0% {
        transform:  translateX(0) scaleX(0);
    }
    40% {
        transform:  translateX(0) scaleX(0.4);
    }
    100% {
        transform:  translateX(100%) scaleX(0.5);
    }

    
}

thead {
  background-color: #F8F9FB; 
}

th {
  padding: 8px;
  text-align: left;
  background: #F8F9FB;
  position: relative;
  height: 35px;
}
/* th:first-child {
  border-top-left-radius: 8px; 
  border-bottom-left-radius: 8px; 
}

th:last-child {
  border-top-right-radius: 8px; 
  border-bottom-right-radius: 8px; 
}
thead tr th:first-child {
  border-top-left-radius: 8px;
}

thead tr th:last-child {
  border-top-right-radius: 8px;
} */

